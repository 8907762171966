module.exports = {
  title: "marcelkirsche.com", // Required
  author: "Marcel Kirsche", // Required
  description:
    "Hi I'm Marcel, a software developer with passion for clean code and lovely architecture. Experienced in building full stack applications using web-based technologies. Keen on learning new things day by day.",
  primaryColor: "#404040", // Required
  secondaryColor: "#0085A1",
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    github: "https://github.com/kirschem",
    stackoverflow: "https://stackoverflow.com/users/8434925/marcel-kirsche",
    linkedin: "https://www.linkedin.com/in/marcel-kirsche-3904a5190",
    mail: "mailto:hello@marcelkirsche.com",
  },
  pathPrefix: "/",
  siteUrl: "https://marcelkirsche.com",
};
